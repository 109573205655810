import axios from "axios";

export const useAxios = async () => {
  // Because your composable is called in the right place in the lifecycle,
  // useRuntimeConfig will also work
  const config = useRuntimeConfig();

  const user = await getCurrentUser();
  let token;
  if (user) {
    token = await user.getIdToken(true);
  }

  const apiClient = axios.create({
    baseURL: config.public.baseUrl,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return { apiClient };
};
