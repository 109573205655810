export async function signin(user) {
  const { apiClient } = await useAxios();
  return apiClient.post("/signin", user);
}

export async function signout() {
  const { apiClient } = await useAxios();
  return apiClient.post("/signout");
}

export async function verifyRecaptcha(token, action) {
  const apiHeaders = await useApiHeaders();
  const { data } = await useFetch("/recaptcha", {
    ...apiHeaders,
    method: "POST",
    body: JSON.stringify({ token, action }),
  });
  return data.value.data;
}

export async function userExists(email) {
  const { apiClient } = await useAxios();
  return apiClient.post("/user-exists", { email });
}

export async function firebaseUserExists(email, password) {
  const { apiClient } = await useAxios();
  return apiClient.post("/firebase-user-exists", { email, password });
}

export async function signup(user) {
  const { apiClient } = await useAxios();
  return apiClient.post("/signup", user);
}

export async function updateUser(userData) {
  //remove subscription property from userData
  delete userData.subscription;
  const apiHeaders = await useApiHeaders();
  const { data } = await $fetch("/api/user/" + userData.id, {
    ...apiHeaders,
    method: "PUT",
    body: JSON.stringify(userData),
  });
  return data.data;
}

export async function deleteUserFromApi(userId) {
  const apiHeaders = await useApiHeaders();
  const { data } = await useFetch("/api/user/" + userId, {
    ...apiHeaders,
    method: "DELETE",
  });
  return data.value.data;
}

export async function setup2fa(userid) {
  const apiHeaders = await useApiHeaders();
  const { data } = await useFetch("/api/user/" + userid + "/setup2fa", {
    ...apiHeaders,
    method: "POST",
  });
  return data.value.data;
}
export async function verify2fa(userid, token) {
  const apiHeaders = await useApiHeaders();
  const { data } = await useFetch("/api/user/" + userid + "/verify2fa", {
    ...apiHeaders,
    method: "POST",
    body: JSON.stringify({ token }),
  });

  return data.value.data;
}

export function forgotPassword(email) {
  return apiClient.post("/forgot", { email }).then((data) => {
    return data.data;
  });
}
export function resetPassword(resetData) {
  return apiClient.post("/reset", resetData);
}
export async function getNotifications() {
  const externalApiHeaders = await useApiHeaders();
  const { data } = await $fetch("/api/usernotification", {
    ...externalApiHeaders,
  });
  return data;
}

export async function markAsRead(ids, userid) {
  const apiHeaders = await useApiHeaders();
  const { data } = await $fetch("/api/usernotification/" + userid + "/mark", {
    ...apiHeaders,
    method: "POST",
    body: JSON.stringify({ ids }),
  });
  return data;
}
